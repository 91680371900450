// https://stackoverflow.com/a/71542987
export function disposeObserver(ro, ref) {
    if (ro == null)
        return;
    if (ref != null) {
        ro.unobserve(ref);
    }
    else {
        ro.disconnect();
    }
}
