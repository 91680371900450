// c.f. https://github.com/panva/jose/blob/e8cf88a4555385ea6ee41a2015f869888649caa2/src/runtime/browser/base64url.ts
// https://web.dev/articles/base64-encoding#btoa_and_atob_with_unicode
export function arrayToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString).replaceAll('=', '');
}
// https://web.dev/articles/base64-encoding#btoa_and_atob_with_unicode
export function base64ToArray(base64) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}
export function toBase64URL(base64) {
    return base64.replaceAll('+', '-').replaceAll('/', '_');
}
export function fromBase64URL(base64url) {
    return base64url.replaceAll('-', '+').replaceAll('_', '/');
}
export function arrayToBase64url(bytes) {
    return toBase64URL(arrayToBase64(bytes));
}
export function base64urlToArray(base64) {
    return base64ToArray(fromBase64URL(base64));
}
